<template>
  <div class="secondhead-text pb-1" :data-cy="`mentor_name_${mentor.lastname[0]}`">
    {{ mentor.prefix }} {{ mentor.firstname }} {{ mentor.lastname }}
    {{ mentor.suffix }}
  </div>
  <div class="grid grid-cols-3 gap-1">
    <div class="">
      <div class="thirdhead-text pb-2">Contact</div>
      <div class="pb-1">
        <!--        <div class="font-semibol">
          {{ `Phone Number${mentor.phone.length > 1 ? "(s)" : ""}:` }}
        </div>-->
        <div v-for="(phone, idx) in mentor.phone" :data-cy="`mentor_phone_${mentor.lastname[0]}_${idx}`" :key="idx">
          {{ phone.phone_number }}
        </div>
      </div>
      <div class="pb-2">
        <!--        <div class="font-semibold">
          {{ `Email${mentor.email.length > 1 ? "(s)" : ""}:` }}
        </div>-->
        <div v-for="(email, idx) in mentor.email" :data-cy="`mentor_email_${mentor.lastname[0]}_${idx}`" :key="idx">
          <a>{{ email.email_address }}</a>
        </div>
      </div>
    </div>
    <div class="">
      <div class="thirdhead-text pb-2">Practice Areas</div>
      <div v-if="mentor.profile.practice_areas.length !== 0" :data-cy="`mentor_pa_${mentor.lastname[0]}`">
        <div
          v-for="practice in mentor.profile.practice_areas"
          :key="practice"
          class="pb-1"
        >
          {{ practice.description }}
        </div>
      </div>
      <div v-else>None Specified</div>
    </div>
    <div class="">
      <div class="thirdhead-text pb-2" :data-cy="`mentor_employer_${mentor.lastname[0]}`">Employer</div>
      <div class="pb-1">
        {{ employer.title ? employer.title + " at " : "" }} {{ employer.name }}
      </div>
      <div class="pb-1">{{ employer.address.address_line_one }}</div>
      <div class="pb-1" v-if="employer.address.address_line_two">
        {{ employer.address.address_line_two }}
      </div>
      <div class="pb-5">
        {{ employer.address.city }},
        {{ employer.address.state }}
        {{ employer.address.zip }}
      </div>
      <div class="pb-1">{{ employer.phone }}</div>
      <div class="pb-1">{{ employer.website }}</div>
    </div>
  </div>
<!--  <div class="border border-cool-gray my-2"></div>-->
<!--  <div class="flex justify-between h-8 mt-2 items-center">-->
<!--    <div class="flex grow py-2.5">-->
<!--      <font-awesome-icon icon="info-circle" class="mr-2 text-purple text-2xl" />-->
<!--      <div class="text-sm">-->
<!--        <div>-->
<!--          Your year-end mentor evaluation for-->
<!--          {{ mentor.prefix }} {{ mentor.full_name }} is waiting.-->
<!--        </div>-->
<!--        <div>-->
<!--          Your responses are confidential and only visible to the program-->
<!--          directors.-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="flex-none">-->
<!--      <button-->
<!--        :data-cy="`mentor_survey_button_${mentor.lastname[0]}`"-->
<!--        class="inline-block py-1 px-3 bg-purple hover:bg-dark-purple rounded-full text-white"-->
<!--        @click="$router.push('/mentor/eval')"-->
<!--      >-->
<!--        Take the Survey-->
<!--      </button>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
export default {
  name: "MentorFullView",
  props: {
    mentor: {
      type: Object,
      required: true,
    },
  },
  computed: {
    employer() {
      const employer = this.mentor.employer.filter((e) => e.current)[0];
      return {
        title: employer.job_title,
        name: employer.employer.employer_name,
        address: employer.employer.address,
        phone: employer.employer.phone?.phone_number,
        website: employer.employer.website,
      };
    },
  },
};
</script>

<style scoped></style>
