<template>
  <div class="grid grid-rows-1 grid-cols-3 gap-4 place-items-center"
  :class="chips.length === 3 ? 'grid-cols-3' : 'grid-cols-4'">
    <div
      v-for="chip in chips"
      :key="chip.title"
      class="inline-block px-2 py-3 border border-purple bg-white text-purple rounded h-full w-full"
      @click="navigate(chip.route)"
    >
      <font-awesome-icon :icon="chip.icon" class="text-2xl mb-1" />
      <div class="font-bold mb-1">{{ chip.title }}</div>
      <div>
        {{ chip.description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelpChips",
  props: {
    chips: {
      type: Array,
      required: true,
    },
  },
  methods: {
    navigate(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped></style>
