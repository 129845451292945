<template>
  <div data-cy="page-mentor">
    <div
      class="header-text pb-3"
      aria-labelledby="header for mentors page"
      ref="headerMentor"
      data-cy="header-mentor"
    >
      Mentor(s)
    </div>
    <div class="subhead-text pb-3">
      Use the information to facilitate regular communication, in person
      experiences and debriefings with your mentor.
    </div>
    <div class="grid grid-cols-3 gap-3">
      <div class="col-start-1 col-span-3 lg:col-span-2 box-style">
        <div class="">
          <div class="secondhead-text">Get to know your mentor</div>
          <div class="subhead-text">
            It's your responsibility to start communication with your mentor.
          </div>
          <HelpChips :chips="mentor_chips" />
        </div>
      </div>
      <div class="col-start-1 col-span-3 lg:col-span-3 row-start-2">
        <div v-if="mentor_loading">
          <div class="box-style">
            <div class="flex items-center justify-center">
              <font-awesome-icon icon="spinner" spin />
              <p class="ml-2">LOADING</p>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            class="box-style mb-5"
            v-for="(mentor, key) in mentors"
            :key="key"
            :data-cy="`mentor_view_${key}`"
          >
            <MentorFullView :mentor="mentor" />
          </div>
        </div>
      </div>
      <div class="col-start-1 col-span-2 lg:col-start-3 lg:col-span-1">
        <div class="box-style h-full">
          <div class="pb-3">
            <div class="secondhead-text">Quick Links</div>
            <div class="subhead-text">Add fieldwork entries.</div>
          </div>
          <div>
            <font-awesome-icon
              icon="users"
              class="mr-3 text-xl text-dark-purple"
            /><span
              class="hover:cursor-pointer"
              @click="
                $router.push({
                  name: 'GenerateForm',
                  params: { type: 'point_of_contact' },
                })
              "
              >Point of Contact</span
            >
          </div>
          <!--<div class="border border-cool-gray my-2"></div>
                    <div>
            <font-awesome-icon
              icon="user-circle"
              class="mr-3 text-xl text-dark-purple"
            /><span
              class="hover:cursor-pointer"
              @click="
                $router.push({
                  name: 'GenerateForm',
                  params: { type: 'ppdp_meeting' },
                })
              "
              >PPDP Meeting</span
            >
          </div>-->
          <div class="border border-cool-gray my-2"></div>
          <div>
            <font-awesome-icon
              icon="compass"
              class="mr-3 text-xl text-dark-purple"
            /><span
              class="hover:cursor-pointer"
              @click="
                $router.push({
                  name: 'GenerateForm',
                  params: { type: 'experience' },
                })
              "
              >Experience</span
            >
          </div>
          <div class="border border-cool-gray my-2"></div>
          <div>
            <font-awesome-icon
              icon="comments"
              class="mr-3 text-xl text-dark-purple"
            /><span
              class="hover:cursor-pointer"
              @click="
                $router.push({
                  name: 'GenerateForm',
                  params: { type: 'debrief' },
                })
              "
              >Debrief</span
            >
          </div>
          <!--<div class="border border-cool-gray my-2"></div>
                    <div>
            <font-awesome-icon
              icon="clock"
              class="mr-3 text-xl text-dark-purple"
            /><span
              class="hover:cursor-pointer"
              @click="
                $router.push({
                  name: 'GenerateForm',
                  params: { type: 'year_end_meeting' },
                })
              "
              >Year-End Meeting</span
            >
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelpChips from "@/components/displays/student-displays/help/HelpChips";
import MentorFullView from "@/components/displays/student-displays/mentor/MentorFullView";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Mentor",
  components: { MentorFullView, HelpChips },
  mounted() {
    this.$refs.headerMentor.focus();
  },
  data() {
    return {
      mentor_chips: [
        {
          icon: "user-circle",
          title: "Introduce Yourself",
          description: "Research your mentor and send an introductory email.",
          route: "#",
        },
        {
          icon: "flag",
          title: "Communicate Often",
          description: "Keep in touch with your mentor by phone or email.",
          route: "#",
        },
        {
          icon: "check-circle",
          title: "Schedule Meetings",
          description:
            "Review your plan (PPDP) and have topic discussions with your mentor.",
          route: "#",
        },
        {
          icon: "compass",
          title: "Show Appreciation",
          description:
            "Invite your mentor and attend the end of year mentor reception.",
          route: "#",
        },
      ],
    };
  },
  // created() {
  //   this.fetchStudentMentors();
  // },
  watch: {
    acad_level() {
      this.fetchStudentMentors();
    },
  },
  methods: {
    ...mapActions(["fetchStudentMentors"]),
  },
  computed: {
    ...mapGetters({ mentors: "student_mentors", acad_level: "getAcadLevel" }),
    ...mapState({
      mentor_loading: (state) => state.student.mentor_loading,
    }),
    // open_mentors() {
    //   return this.mentors.filter(m => !m.is_pairing_closed)
    // }
  },
};
</script>

<style scoped></style>
